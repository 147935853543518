import React,{useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MUIDataTable from "mui-datatables";
import {APP_URL,API_URL} from '../../config.json';
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {pathSpliter,checkAuthBranchWare,dateTimeFormat,currentDateTime} from '../../lib/functions'
import {currentRouteSet} from '../../actions/actions';
import moment from 'moment';

import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
  } from '@material-ui/pickers';

    const MaterialQuantity = ({location,currentRouteSet,authInfo})=>{
    const classes = useStyles();

    const [selectedDate, handleDateChangeSet] = useState(currentDateTime);
    const [fieldDisabled, fieldDisabledSet] = useState(false);//add 1

    let [loadingSave,loadingSaveSet] = useState(false)
    let [loadingList,loadingListSet] = useState(false)
    let [materials,materialsSet] = useState([])
    let [selectedMaterial,selectedMaterialSet] = useState(null)
    let [adjustmentCode,adjustmentCodeSet] = useState('');
    let [adjustments,adjustmentsSet] = useState([]);
    let [adjust_type,adjust_typeSet] = useState('');

    let [adjustmentQty,adjustmentQtySet] = useState('')
    let [adjustmentRate,adjustmentRateSet] = useState(0)
    let [adjustmentTotal,adjustmentTotalSet] = useState(0)
    let [adjustmentNote,adjustmentNoteSet] = useState('')
    let [adjustmentAction,adjustmentActionSet] = useState('create')
    let [adjustmentId,adjustmentIdSet] = useState(0)
    let [selectedMaterialAdjustType,selectedMaterialAdjustTypeSet] = useState({adjust_type_id:'Add',adjust_type:'Add'})
    let  materialAdjustTypes = [{adjust_type_id:'Add',adjust_type:'Add'},{adjust_type_id:'Subtract',adjust_type:'Subtract'}]




    let materialRef = useRef(null)
    let rateRef = useRef(null)
    let qtyRef = useRef(null)
    let totalRef = useRef(null)
    let noteRef = useRef(null)
    let dateRef = useRef(null)
    let saveRef = useRef(null)
    let materialAdjustTypeRef = useRef(null)
   

    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
        getMaterials()
        getAdjustCode()
         getAdjustments()
    },[]);


   let getMaterials = async ()=>{
        await axios.post(`${API_URL}/api/get-materials`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
              materialsSet(res.data.message)
        })
    }

     let getAdjustments = async ()=>{
     await axios.post(`${API_URL}/api/get-material-adjustments`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
            adjustmentsSet(res.data)
     })
    }

    let getAdjustCode = async ()=>{
        
        await axios.get(`${API_URL}/api/get-material-adjustment-code`,{headers:{'auth-token':authInfo.token}}).then(res=>{
            adjustmentCodeSet(res.data)
      })
    }

    const saveFormAction = async ()=>{
        if(selectedMaterial==null){
            swal({title:'select material',icon:'warning'});return false;
        }
        if(adjustmentQty<=0){
            swal({title:'Invalid Quantity.',icon:'warning'});return false;
        }

        if(selectedMaterialAdjustType==null){  
        swal({title:'Select Adjustment Type',icon:'warning'});return false;
        }
        let payload = {
            adjust_qty_id: adjustmentId,
            adjust_prod_id: selectedMaterial.material_id,
            adjust_type : selectedMaterialAdjustType.adjust_type,
            adjust_rate: adjustmentRate,
            adjust_qty: adjustmentQty,
            adjust_total: adjustmentTotal,
            adjust_note: adjustmentNote,
            adjust_c_isodt: selectedDate,
            adjustmentAction : adjustmentAction
        }

            loadingSaveSet(true) 

            await axios.post(`${API_URL}/api/save-material-adjustment`,{payload},{headers:{'auth-token':authInfo.token}}).then(res=>{
                loadingSaveSet(false)
                if(!res.data.error){
                  getAdjustCode()
                  getAdjustments() 
                  swal({title:res.data.message,icon:'success'})
                  selectedMaterialSet(null)
                  adjustmentQtySet('')
                  adjustmentRateSet(0)
                  adjustmentTotalSet(0)
                  adjustmentNoteSet('')
                  adjustmentActionSet('create')
                  adjustmentIdSet(0)
                  handleDateChangeSet(currentDateTime)
                  selectedMaterialAdjustTypeSet({adjust_type_id:'Add',adjust_type:'Add'})
                  fieldDisabledSet(false)


                }
          })
        
          
    }

    const adjustmentEdit = (row,index)=>{
     //console.log(adjustments[index])
          adjustmentCodeSet(adjustments[index].adjust_qty_code)
          selectedMaterialAdjustTypeSet({adjust_type_id:adjustments[index].adjust_type_id,adjust_type:adjustments[index].adjust_type})
          selectedMaterialSet({material_id:adjustments[index].adjust_prod_id,material_name:adjustments[index].material_name})
          adjustmentRateSet(parseFloat(adjustments[index].adjust_rate).toFixed(2))
          adjustmentQtySet(adjustments[index].adjust_qty)
          adjustmentTotalSet(adjustments[index].adjust_total)
          adjustmentNoteSet(adjustments[index].adjust_note)
          handleDateChangeSet(adjustments[index].adjust_c_isodt)
          adjustmentIdSet(adjustments[index].adjust_qty_id)
          adjustmentActionSet('update')
          fieldDisabledSet(true)
        
    }


    const adjustmentDelete = async (index)=>{ 
      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async res=>{
        if(res){
          let adjustmentId = adjustments[index].adjust_qty_id;
      await axios.post(`${API_URL}/api/delete-material-adjustment`,{adjustmentId},{headers:{'auth-token':authInfo.token}}).then(res=>{
        if(!res.data.error){
           getAdjustments()
          swal({title:res.data.message,icon:'success'})
        }
        })
        }else{
          return false;
        }
      })
      
    }
  
    const ActionOptions = (props)=>{
        return(<div style={{textAlign:'right'}}>
           {
    authInfo.role !='user'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>adjustmentEdit(props.rowData,props.rowIndex)} />
          <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>adjustmentDelete(props.rowIndex)}/>         
   </>):''
 }
         
        </div>)
      
      }
    
    const columns = [
        {name: "adjust_qty_id",options: { display: 'excluded' }},
        {name: "adjust_qty_code",label: "Material Qty Adjustment Code",options: {filter: true,sort: true}},
        {name: "adjust_c_isodt",label: "created date & time",options: 
        {filter: true,sort: true,
          customBodyRender:(value,tableMeta)=>{
            return(<p>{moment(tableMeta.rowData[2]).format(dateTimeFormat)}</p>) 
          }
        }},
        {name: "material_name",label: "Material Name",options: {filter: true,sort: true}},
        {name: "adjust_type",label: "Adjustment Type",options: {filter: true,sort: true}},
        {name: "adjust_qty",label: "Quantity",options: {filter: true,sort: true}},
        {name: "adjust_rate",label: "Rate",options: {filter: true,sort: true}},
        {name: "adjust_total",label: "Total",options: {filter: true,sort: true}},
        {name:"actions",options: {filter: false,sort: false,
          customBodyRender:(value,tableMeta)=>{
            return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
               /> ); 
        }
        },headerStyle: {
          textAlign:'right'
        }}
       ];
              
       const options = {
         filterType: 'checkbox',
         selectableRows: 'none',
         display: "excluded"
        }

    return (
        <div className={classes.root}>
         
            <Paper className={classes.paper} style={{marginTop:'-15px'}}>
            <h2 className={classes.pageEntryLabel}>Material Quantity Adjustment Entry</h2>
             
            <Grid container spacing={2}>
            <Grid item xs={12} sm={2} > 
            <TextField  autoComplete='off'  className={classes.fullWidth}  value={adjustmentCode} 
            label="Material Qty Adjustment  Code" disabled name="product_adjustment_code" variant="outlined" size="small"  />
            </Grid>
            <Grid item xs={12} sm={3}>
            <Autocomplete 
              openOnFocus={true}
              autoHighlight={true}
              style={{width:'100%',height:'20px'}}
              options={materials}
              disabled={fieldDisabled}
              value={selectedMaterial}
              inputRef={materialRef}
              onKeyDown={(e)=>{
                if(e.key=='Enter'){
                 qtyRef.current.focus();
                }
              }}
              onChange={(e,material)=>{
                    if(material==null){
                        selectedMaterialSet(null)
                        return false
                    }
                    selectedMaterialSet(material)
                    adjustmentRateSet(parseFloat(material.material_purchase_rate).toFixed(2))
                    
               }}
              size="small"
              getOptionLabel={(option) => option.material_name}
             
              renderInput={(params) => <TextField 
              
                {...params} 
                label="Adjustment Material" 
                variant="outlined"
                
                />}
                
          />
            </Grid>
            <Grid item xs={12} sm={2} > 
            <TextField type="number" autoComplete='off' disabled  className={classes.fullWidth}  value={adjustmentRate} 
            label="Adjustment  Rate" name="adjustment_rate" variant="outlined" size="small" 
            />
            </Grid>

{/*ADJUST TYPE START*/}
<Grid item xs={12} sm={3}  className={classes.plusLinkDiv}>
                <Autocomplete
                style={{ width: '100%' }}
                options={materialAdjustTypes}
                size="small"
                classes={{
                    option: classes.option
                }}
                autoHighlight={true}
                openOnFocus={true}
                disabled={fieldDisabled}
                getOptionLabel={(option) =>option.adjust_type}
                value={selectedMaterialAdjustType} 
                  onChange={(event,selectedObj)=>{

                  selectedMaterialAdjustTypeSet(selectedObj)
                  
                }}


                loading={materialAdjustTypes.length==0?true:false}
                renderInput={(params) => (
                    <TextField
                    inputRef={materialAdjustTypeRef}
                    onKeyDown={event => {
                        if (event.key === "Enter") {
                          materialAdjustTypeRef.current.focus() 
                        }
                      }}
                    {...params}
                    label="Choose Adjustment type"
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {customerPayAccs.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment} */}
                          </React.Fragment>
                        ),
                    }}
                    />
                )}
                /> 
            </Grid>


{/*ADJUST TYPE END*/}





            <Grid item xs={12} sm={2} > 
            <TextField type="number" autoComplete='off'  className={classes.fullWidth}  value={adjustmentQty} 
            label="Adjustment Qty" name="adjustment_qty" variant="outlined" size="small" 
            inputRef={qtyRef}
            onKeyDown={(e)=>{
              if(e.key=='Enter'){
               noteRef.current.focus();
              }
            }}
            
            onChange={(e)=>{
                adjustmentQtySet(e.target.value)
                if(e.target.value>0){
                    adjustmentTotalSet(parseFloat(e.target.value)*adjustmentRate)
                }
            }}
            
            />
            </Grid>

            <Grid item xs={12} sm={2} > 
            <TextField type="number" disabled autoComplete='off'  className={classes.fullWidth}  value={adjustmentTotal} 
            label="Adjustment Total" name="adjustment_total" variant="outlined" size="small" 
            inputRef={totalRef}
            onKeyDown={(e)=>{
              if(e.key=='Enter'){
               noteRef.current.focus();
              }
            }} />
            </Grid>
            <Grid item xs={12} sm={3} style={{marginRight:'10px'}}>
            
            <TextareaAutosize value={adjustmentNote}  name="adjustment_note" 
            ref={noteRef}
             onKeyDown={(e)=>{
               if(e.key=='Enter'){
                dateRef.current.focus();
               }
             }}

             onChange={(e)=>adjustmentNoteSet(e.target.value)}
             
             style={{float:'left',marginTop:'20px',width: '325px',height: '40px',
             marginTop: '1px',width: '200px'}} aria-label="Adjustment Note..." rowsMin={3} placeholder="Adjustment Note..." />

            </Grid>
            <Grid item xs={12} sm={3}>
            <MuiPickersUtilsProvider  utils={DateFnsUtils}>
            <KeyboardDateTimePicker
            style={{ width: '100%',marginTop: '' }}
            value={selectedDate}
            inputRef={dateRef}
            onKeyDown={(e)=>{
              if(e.key=='Enter'){
               saveRef.current.click();
              }
            }}
            onChange={handleDateChangeSet}
            name="adjustment_date_time"
            label="Adjustment Date & Time"
            format="yyyy/MM/dd hh:mm a"
               />
            </MuiPickersUtilsProvider>
          </Grid>

           </Grid>
           <Grid item xs={12}>
          <Button style={{marginTop: '25px'}}
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<SaveIcon/>}
            buttonRef={saveRef}
            disabled={loadingSave}
            onClick={saveFormAction}
        >
        Save
      </Button>
  </Grid>
            </Paper>
            {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Damage Material  List"}
      data={adjustments}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }
        </div>
    )
}



const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  root: {},
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: '25ch',
   },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    pageEntryLabel:{
        color: '#7754cc',
        margin: '0px',
        padding: '0px',
        marginTop: '-11px',
        textAlign: 'left',
        marginLeft: '0px',
        marginBottom: '5px',
    },
    fullWidth:{
        width:'100%'
    },
    option: {
        fontSize: 15,
        '& > span': {
          marginRight: 10,
          fontSize: 18,
        },
      }
  }));
  
  const mapStateToPops = (state)=>{
        return {
          currentRoute:state.currentRouteReducer,
          authInfo:state.authInfoReducer,
        }
  }
  export default connect(mapStateToPops,{currentRouteSet})(MaterialQuantity);